import gql from 'graphql-tag'

export const VIAL_SEARCH_QUERY = gql`
  query VialSearch(
    $calibrationDate: Date!
    $treatmentDate: Date!
    $treatmentTime: String!
    $optimumShipDate: Date!
    $mnxRushOrder: Boolean
  ) {
    vialSearch(
      calibrationDate: $calibrationDate
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      optimumShipDate: $optimumShipDate
      mnxRushOrder: $mnxRushOrder
    ) {
      surplus
      vials {
        available
        dosage
        surplus
        # productId
      }
    }
  }
`
