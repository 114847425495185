import { useMutation } from '@apollo/react-hooks'
import { navigate } from '@reach/router'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AccessoryInput,
  OrderFormTypeEnum,
  RegionCodeEnum,
  TreatmentFreeReasonsEnum,
} from '../../../__generated__/globalTypes'
import { useAuth } from '../../../context/auth-context'
import { useUser } from '../../../context/user-context'
import { VialMapType } from '../../../context/VialSearchContext'
import { useOrderForm, useVialSearch } from '../../../hooks'
import {
  ORDERS_CREATE_PATH,
  ORDERS_INDEX_PATH,
  ORDER_SHOW_PATH,
} from '../../../routes'
import { client } from '../../../schema/apollo-client'
import {
  CREATE_ORDER_MUTATION,
  SET_ORDER_FILTERS_MUTATION,
} from '../../../schema/mutations'
import {
  CreateOrder,
  CreateOrderVariables,
  CreateOrder_createOrder_Order,
} from '../../../schema/mutations/__generated__/CreateOrder'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../../schema/mutations/__generated__/SetOrderFilters'
import { ORDER_FILTERS_QUERY } from '../../../schema/queries'
import { CalibrationDates } from '../../../schema/queries/__generated__/CalibrationDates'
import { CheckDeliveryCharges_checkDeliveryCharges } from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { GetCustomer_customer } from '../../../schema/queries/__generated__/GetCustomer'
import { themeUtils } from '../../../themeUtils'
import { Notification, dateOnly } from '../../../utils'
import { CheckSquareIcon } from '../../Icons'
import { Button } from '../../Shared'
import { OrderConfirmationModal } from './OrderConfirmationModal'
import { OrderContactModal } from './OrderContactModal'
import { OrderFormData, VialSearchResultOptionType } from './OrderFormDataTypes'

interface Props {
  mnxRushOrder: boolean
  disabled: boolean
  isOpen: boolean
  formData: OrderFormData
  deliveryChargeData: CheckDeliveryCharges_checkDeliveryCharges | undefined
  calibrationWeekData?: CalibrationDates | undefined
  selectedVials?: VialMapType
  onSubmit: () => void
  onClose: () => void
  onConfirm: (action: any) => void
  customer: GetCustomer_customer | undefined
  createAccessoryOnlyOrder: boolean
}

interface TreatmentOrder {
  calibrationDate: string
  quantity: number
  lineItemNumber: number
  dosage: number
  freeReason?: TreatmentFreeReasonsEnum
}

export const OrderCreateButton: React.FC<Props> = ({
  mnxRushOrder,
  disabled,
  formData,
  deliveryChargeData,
  calibrationWeekData,
  selectedVials,
  onSubmit,
  onClose,
  onConfirm,
  isOpen,
  customer,
  createAccessoryOnlyOrder,
}) => {
  const { t } = useTranslation(['orderCreateButton'])
  const auth = useAuth()
  const user = useUser()
  const { getAllSelectedVialOption } = useVialSearch()
  const { formType } = useOrderForm()

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const [createOrder, { error }] = useMutation<
    CreateOrder,
    CreateOrderVariables
  >(CREATE_ORDER_MUTATION)

  const [submitting, setSubmitting] = useState(false)
  const [showContactDetails, setShowContactDetails] = useState(false)
  const createdOrderRef = useRef<CreateOrder_createOrder_Order | null>(null)

  useEffect(() => {
    if (error) Notification.error(error.message)
  }, [error])

  const splitTreatmentData = (): Array<TreatmentOrder> => {
    let treatmentsArr = Array<TreatmentOrder>()
    if (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED) {
      const selectedDose = getAllSelectedVialOption()
      if (selectedDose.length) {
        selectedDose.forEach(vialData => {
          const { dosage, calibration_date } = vialData
          treatmentsArr.push({
            dosage: dosage,
            quantity: 1,
            calibrationDate: calibration_date,
            lineItemNumber: Number(Date.now()) as number,
          })
        })
      }
    } else {
      if (selectedVials && selectedVials.size) {
        for (const [dosage, selection] of selectedVials) {
          const vialOrders = Array.from(selection.entries())
          vialOrders.forEach(([calDate, quantity]) => {
            for (let index = 0; index < quantity; index++) {
              treatmentsArr.push({
                dosage: dosage,
                quantity: 1,
                calibrationDate: calDate,
                lineItemNumber: Number(Date.now()) as number,
              })
            }
          })
        }
      }
    }

    return treatmentsArr
  }

  const generateOrderData = () => {
    if (!formData) return []

    const treatments = splitTreatmentData()
    const selectedDose = getAllSelectedVialOption()

    let orderDates = new Set<string>()
    if (treatments.length > 0) {
      treatments.forEach(treatment => orderDates.add(treatment.calibrationDate))
    } else {
      orderDates.add((new Date()).toDateString())
    }

    if (!orderDates) return []

    return [...orderDates].map((calDate, index) => {
      let treatmentsForCalDate = Array<TreatmentOrder>()
      let treatmentsForCalDateV2 = Array<VialSearchResultOptionType>()
      if (treatments) {
        treatmentsForCalDate = treatments.filter(
          treatment => treatment.calibrationDate === calDate
        )
      }

      let useBwxtSurplusInventory: boolean = formData.surplusData[calDate]
      if (
        selectedDose.length &&
        formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
      ) {
        treatmentsForCalDateV2 = selectedDose.filter(
          treatment => treatment.calibration_date === calDate
        )
        useBwxtSurplusInventory = treatmentsForCalDateV2.some(v => v.surplus)
      }

      const isUsaOrCanadianCustomer = [
        RegionCodeEnum.US,
        RegionCodeEnum.CA,
        // @ts-ignore
      ].includes(user?.customer?.region?.code)
      const isKorea = user?.customer?.country === 'KR'
      if (isUsaOrCanadianCustomer || isKorea) {
        treatmentsForCalDate = treatmentsForCalDate.map((t, idx) => {
          if (idx > 0 || index > 0) {
            t.freeReason = TreatmentFreeReasonsEnum.X
          }

          return t
        })
      }

      let accessories = Array<AccessoryInput>()
      if (formData.adminSets) {
        accessories = formData.adminSets.flatMap(accessory => {
          const { confirmed, hasError, quantity, ...accessoryProps } = accessory
          const accessoryOrder = {
            quantity: parseInt(`${quantity}`),
            ...accessoryProps,
          }
          return accessoryOrder.quantity > 0 && confirmed ? accessoryOrder : []
        })
      }

      const shipDate = () => {
        const optimumShipDatesByCalDate = {
          ...formData.optimumShipDatesByCalDate,
        }
        if (createAccessoryOnlyOrder) {
          // accessory only orders cannot have selected calibration week
          return moment(optimumShipDatesByCalDate['']).format('YYYY-MM-DD')
        } else {
          if (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED) {
            if (selectedDose.length) {
              const optimumShipDate = moment
                .max(
                  selectedDose.map(vialData =>
                    moment.utc(vialData.optimum_ship_date)
                  )
                )
                .format('YYYY-MM-DD')

              console.log('optimumDate:', optimumShipDate)
              return optimumShipDate

              // const latestOptimumShipDate = new Date(
              //   Math.max(
              //     ...selectedDose.map(vialData =>
              //       new Date(vialData.optimum_ship_date).getTime()
              //     )
              //   )
              // )

              // return latestOptimumShipDate
            }
          } else {
            // We need to strip out any optimumShipDatesByCalDate that are NOT present
            // in the treatments array. This happens when the user browses a different
            // calibration week but does not add a dose, and so the system still uses
            // the earliest ship date even though that is for a calibration date that
            // was not selected.
            Object.keys(optimumShipDatesByCalDate).forEach(shipDate => {
              if (!orderDates.has(shipDate)) {
                delete optimumShipDatesByCalDate[shipDate]
              }
            })
            const sorted = Object.values(optimumShipDatesByCalDate).sort(
              (a: Date, b: Date) => Number(b) - Number(a)
            )
            return moment(sorted[0]).format('YYYY-MM-DD') //convertDateOnlyToString(sorted[0], DATE_ISO_FORMAT)
          }
        }
      }

      return {
        mnxRushOrder: mnxRushOrder,
        treatmentDate: dateOnly(formData.treatmentDate as Date),
        treatmentTime: formData.treatmentTime,
        shipDate: shipDate(),
        patientRef: formData?.patientRef,
        poNumber: formData?.poNumber,
        treatments: treatmentsForCalDate,
        useBwxtSurplusInventory: useBwxtSurplusInventory,
        accessories: index === 0 ? accessories : null,
        addressId: formData.addressId,
        shippingNote: formData.address?.shippingNote,
        sourceType: formData.sourceType,
        treatingPhysicianName: formData.treatingPhysicianName,
        treatingPhysicianId: formData.treatingPhysicianId,
        referringPhysicianName: formData.referringPhysicianName,
        referringPhysicianId: formData.referringPhysicianId,
        treatmentIntent: formData.treatmentIntent ?? null,
      }
    })
  }

  const closeConfirmModal = (): void => {
    onClose()
    setSubmitting(false)
  }

  const performOnConfirm = async (): Promise<void> => {
    try {
      if (!formData || disabled) return

      setSubmitting(true)

      const { data, errors } = await createOrder({
        variables: {
          input: generateOrderData(),
        },
      })

      if (errors) {
        errors.forEach(error => Notification.error(error.message))
        closeConfirmModal()
      } else {
        if (data && data.createOrder) {
          if (data.createOrder.__typename === 'Error') {
            Notification.error(
              t('toast.error', { ns: ['orderCreateButton'] }),
              {
                timeout: false,
                killer: true,
              }
            )
            data.createOrder.errors.forEach(error => {
              if (error.message) {
                Notification.error(error.message, {
                  timeout: false,
                  killer: [
                    'ORDER_DATE_MISMATCH_ERROR',
                    'BUILD_PLAN_STATUS_CHANGE_ERROR',
                  ].includes(error.type),
                })
              }

              if (
                [
                  'ORDER_DATE_MISMATCH_ERROR',
                  'BUILD_PLAN_STATUS_CHANGE_ERROR',
                ].includes(error.type)
              ) {
                navigate(ORDERS_CREATE_PATH)
              }
            })
            closeConfirmModal()
            window.scrollTo(0, 0)
          } else {
            createdOrderRef.current = data.createOrder
            closeConfirmModal()
            const shippingTimeline =
              formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
                ? formData?.activityFormShippingTimeline
                : formData?.shippingTimeline
            const showContactDetails =
              formData && shippingTimeline && shippingTimeline <= 1
            if (showContactDetails) {
              setShowContactDetails(true)
            } else {
              onSuccess()
            }
          }
        }
      }
    } catch (error) {
      //@ts-ignore
      Notification.error(error.message)
      closeConfirmModal()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSuccess = async () => {
    Notification.success(t('toast.success', { ns: ['orderCreateButton'] }), {
      killer: true,
    })

    const updatedDataFromCache = client.readQuery({
      query: ORDER_FILTERS_QUERY,
    })

    const newData = {
      ...updatedDataFromCache.orderFilters,
      status: null,
    }

    await setFiltersMutation({
      variables: {
        input: newData,
      },
    })

    if (createdOrderRef.current) {
      const linkState = {
        showOrderMissinigInfoModal: true,
      }
      navigate(
        ORDER_SHOW_PATH.replace(
          ':orderId',
          createdOrderRef.current.orderNumber
        ),
        {
          state: linkState,
        }
      )
    } else {
      navigate(ORDERS_INDEX_PATH)
    }
  }

  return (
    <>
      {auth?.user?.canCreateOrders &&
        (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED ? (
          <Button
            backgroundColor={themeUtils.colors.green}
            isFullWidth
            isDisabled={disabled}
            onClick={onSubmit}
            data-cy="create-order-btn"
          >
            {t('label', { ns: ['orderCreateButton'] })}
          </Button>
        ) : (
          <Button
            backgroundColor={themeUtils.colors.green}
            marginTop="0"
            height="6rem"
            paddingX="2rem"
            paddingY="2rem"
            justifyContent="space-between"
            isDisabled={disabled}
            rightIcon={CheckSquareIcon}
            onClick={onSubmit}
            isFullWidth
            data-cy="create-order-btn"
          >
            {t('label', { ns: ['orderCreateButton'] })}
          </Button>
        ))}
      <OrderConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => onConfirm(performOnConfirm)}
        orderData={formData}
        deliveryChargeData={deliveryChargeData}
        calibrationWeekData={calibrationWeekData}
        selectedVials={selectedVials}
        submitting={submitting}
        mnxRushOrder={mnxRushOrder}
        customer={customer}
        createAccessoryOnlyOrder={createAccessoryOnlyOrder}
      />
      {showContactDetails && (
        <OrderContactModal isOpen onClose={() => onSuccess()} />
      )}
    </>
  )
}
