import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Box, Flex, Grid, Text, useDisclosure } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import styled from '@emotion/styled'
import { parseISO } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'use-media'
import {
  OrderFormTypeEnum,
  SourceTypeEnum,
} from '../../../__generated__/globalTypes'
import { DATE_ISO_FORMAT } from '../../../constants'
import { GetMyPortalUser_me } from '../../../context/__generated__/GetMyPortalUser'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { useUser } from '../../../context/user-context'
import { useDisplayReferringPhysician, useOrderForm, useVialSearch } from '../../../hooks'
import {
  CALIBRATION_DATES_QUERY,
  OPTIMUM_SHIP_DATE_QUERY,
  VIAL_SEARCH_QUERY,
} from '../../../schema/queries'
import {
  CalibrationDates,
  CalibrationDatesVariables,
} from '../../../schema/queries/__generated__/CalibrationDates'
import {
  CheckDeliveryCharges,
  CheckDeliveryChargesVariables,
} from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import {
  OptimumShipDate,
  OptimumShipDateVariables,
} from '../../../schema/queries/__generated__/OptimumShipDate'
import {
  VialSearch,
  VialSearchVariables,
} from '../../../schema/queries/__generated__/VialSearch'
import { CHECK_DELIVERY_CHARGES_QUERY } from '../../../schema/queries/CheckDeliveryChargesQueries'
import { breakpoints, MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { OrderData, ThemeVersion } from '../../../types'
import {
  dateOnly,
  formatDate,
  formattedTime,
  Notification,
} from '../../../utils'
import { getVialsIfParamsDefined } from '../../../utils/getVialsIfParamsDefined'
import {
  AmTreatmentUnfulfillable,
  CalibrationWeekToggle,
  VialSearchResults,
  VialSearchUnfulfillable,
} from '../../Dashboard/VialSearch'
import { Checkbox, DatePickerWidget } from '../../Forms'
import { AccessoryIcon, EmailIcon } from '../../Icons'
import {
  calculateShippingTimeline,
  countryConfiguration,
  isDateDisabled,
  minOrderTreatmentDate,
  mnxRushWindow,
  totalActivityCalc,
  totalActivityFocusedCalc,
  withScreenStartTimeCheck,
} from '../../lib'
import {
  Container,
  FormHeader,
  InlineErrorMessage,
  InputV2,
  LinkButton,
  Option,
  WidgetHeader,
} from '../../Shared'
import { ShowExpeditedChargeMsg } from '../../Shared/ShowExpeditedChargeMsg'
import {
  OrderNewWidget,
  PatientIntent,
  ShippingAddress,
  TreatingPhysician,
  ReferringPhysician,
  TreatmentTime,
} from '../NewOrderForm'
import { GET_CUSTOMER_QUERY } from './../../../schema/queries/CustomerQueries'
import { Accessories } from './Accessories'
import { AccessoryFields } from './AccessoryFields'
import { ActivityAtTreatment } from './ActivityAtTreatment'
import { AddAccessoryButton } from './AddAccessoryButton'
import { OrderCreateButton } from './OrderCreateButton'
import {
  AdminSetType,
  DesiredActivityAtTreatment,
  OrderFormData,
} from './OrderFormDataTypes'
import { TargetActivityAtTreatmentField } from './TargetActivityAtTreatmentField'
import { Treatments } from './Treatments'

type Props = {
  preSelectedOrderData?: OrderData
}
const { midnightBlue, red } = themeUtils.colors

const BoxContainer1 = styled(Box)`
  display: flex;
  align-self: stretch;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 2.5rem;

  ${mqNames(MEDIUM)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const BoxContainer2 = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Heading = styled(FormHeader)`
  display: none;
  ${mqNames(MEDIUM)} {
    display: block;
  }
`

const MediumContainer = styled(Flex)`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid rgba(0, 57, 104, 0.1);
`

export const selectedAddress = (
  addressId: string,
  user: GetMyPortalUser_me | null
) => {
  return user?.shippingAddresses?.find(
    (address: { id: string }) => address.id === addressId
  )
}

export const isMnxEligibleAddress = (
  addressId: string,
  user: GetMyPortalUser_me | null
): boolean => {
  return Boolean(selectedAddress(addressId, user)?.mnxEligibleForRush)
}

export const getDefaultAddressId = (
  user: GetMyPortalUser_me | null
): string => {
  if (!user?.preferredShippingAddressId) return ''

  const address = selectedAddress(user.preferredShippingAddressId, user)
  if (address) {
    return user.preferredShippingAddressId
  } else {
    return ''
  }
}

export const OrderCreateWidget: FC<Props> = ({ preSelectedOrderData }) => {
  const { t } = useTranslation(['orderCreate'])
  const [screenOpenedAt] = useState(new Date())
  const {
    selectedVials,
    selectedCalibrationDate,
    setSelectedCalibrationDate,
    clearSelectedVials,
    setDefaultSelectedVials,
    clearSelectedActivityVials,
    selectedActivityVials,
  } = useVialSearch()
  const {
    formType,
    setFormType,
    prevFormSource,
    setHideSwitchFormValue,
    setDefaultFormType,
  } = useOrderForm()
  const user = useUser()
  const customerSalesRep = user?.customer.regionalCustomerCareRep
  const [createAccessoryOnlyOrder, setCreateAccessoryOnlyOrder] = useState(
    false
  )
  const [pageTitle, setPageTitle] = useState(
    `${t('orderCreateWidget.vialSearch', { ns: ['orderCreate'] })}`
  )
  const [dateTitle, setDateTitle] = useState(
    `${t('orderCreateWidget.treatDate', { ns: ['orderCreate'] })}`
  )
  const [dateTitlePlaceholder, setDateTitlePlaceholder] = useState(
    `${t('orderCreateWidget.treatDatePlaceholder', { ns: ['orderCreate'] })}`
  )
  const [timeTitle, setTimeTitle] = useState(
    `${t('orderCreateWidget.treatTime', { ns: ['orderCreate'] })}`
  )
  const [timeTitlePlaceholder, setTimeTitlePlaceholder] = useState(
    `${t('orderCreateWidget.treatTimePlaceholder', { ns: ['orderCreate'] })}`
  )
  const inlineErrorMessageStyleProps = {
    color: '#003968',
    icon: 'info' as Icons,
    backgroundColor: '#EBEFF3',
    padding: '8px 10px',
    borderRadius: '8px',
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')

  const defaultTreatmentDate = preSelectedOrderData?.treatmentDate || null

  const defaultAddressId = getDefaultAddressId(user)

  const [formData, setFormData] = useState<OrderFormData>({
    treatmentDate: defaultTreatmentDate,
    treatmentTime: preSelectedOrderData
      ? preSelectedOrderData.treatmentTime
      : '',
    patientRef: '',
    poNumber: '',
    validBlanketPO: false,
    optimumShipDate: undefined,
    optimumShipDatesByCalDate:
      preSelectedOrderData?.optimumShipDatesByCalDate || {},
    targetActivityAtTreatment: undefined,
    totalActivityAtTreatment: 0,
    surplusData: preSelectedOrderData?.surplusData || {},
    shippingTimeline: undefined,
    showShippingError: false,
    addressId: defaultAddressId,
    address: user?.preferredShippingAddress || undefined,
    adminSets: Array<AdminSetType>(),
    defaultAdminSet:
      user?.preferredShippingAddress.defaultAdminSet || undefined,
    sourceType: SourceTypeEnum.THERASPHERE_NOW,
    treatingPhysicianId: '',
    treatingPhysicianName: '',
    noOfVials: preSelectedOrderData ? preSelectedOrderData.noOfVials : 0,
    desiredActivityAtTreatmentSet: preSelectedOrderData
      ? preSelectedOrderData.desiredActivityAtTreatmentSet
      : Array<DesiredActivityAtTreatment>(),
    vialSearchResult: null,
    activityFormShippingTimeline: undefined,
    activityFormShowShippingError: false,
    treatmentIntent: null,
  })

  const {
    optimumShipDate,
    targetActivityAtTreatment,
    totalActivityAtTreatment,
    treatmentDate,
    treatmentTime,
    shippingTimeline,
    showShippingError,
    poNumber,
    validBlanketPO,
    addressId,
    defaultAdminSet,
    adminSets,
    patientRef,
    treatmentIntent,
  } = formData
  const treatmentDateOnly = treatmentDate && dateOnly(treatmentDate)

  const displayReferringPhysician = useDisplayReferringPhysician()

  // @ts-ignore
  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY)

  const isMnxTreatmentDate = () => {
    if (!treatmentDate) return false
    return mnxRushWindow(isMnxEligibleOrder())
      .map(d => dateOnly(d))
      .includes(dateOnly(treatmentDate))
  }

  const [
    searchVials,
    { data: vialData, loading: vialsLoading, error: vialSearchError },
  ] = useLazyQuery<VialSearch, VialSearchVariables>(VIAL_SEARCH_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [
    getCalibrationDates,
    { data: calWeeksData, error: calWeeksError },
  ] = useLazyQuery<CalibrationDates, CalibrationDatesVariables>(
    CALIBRATION_DATES_QUERY,
    { fetchPolicy: 'cache-and-network' }
  )

  const [checkDeliveryCharge, { data: deliveryChargeData }] = useLazyQuery<
    CheckDeliveryCharges,
    CheckDeliveryChargesVariables
  >(CHECK_DELIVERY_CHARGES_QUERY, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    if (preSelectedOrderData) {
      changeCalibrationDate(preSelectedOrderData.calibrationDate)
      setDefaultSelectedVials(preSelectedOrderData.vials)
      setDefaultFormType(preSelectedOrderData.formType)
    }
  }, [])

  useEffect(() => {
    const error = vialSearchError || calWeeksError
    if (error) Notification.error(error.message)
  }, [vialSearchError, calWeeksError])

  useEffect(() => {
    if (
      treatmentDateOnly &&
      treatmentTime &&
      selectedCalibrationDate &&
      optimumShipDate
    ) {
      searchVials({
        variables: {
          calibrationDate: selectedCalibrationDate,
          treatmentDate: treatmentDateOnly,
          treatmentTime: treatmentTime,
          optimumShipDate: formatDate(optimumShipDate, DATE_ISO_FORMAT),
          mnxRushOrder: isMnxTreatmentDate(),
        },
      })
    }
  }, [
    selectedCalibrationDate,
    treatmentDateOnly,
    treatmentTime,
    optimumShipDate,
    searchVials,
  ])

  useEffect(() => {
    if (treatmentDate && treatmentTime) {
      getCalibrationDates({
        variables: {
          treatmentDate: treatmentDateOnly,
        },
      })
    }
  }, [treatmentDate, treatmentTime, getCalibrationDates])

  useEffect(() => {
    if (treatmentDate && treatmentTime && addressId) {
      checkDeliveryCharge({
        variables: {
          treatmentTime: treatmentTime,
          treatmentDate: treatmentDateOnly,
          addressId: addressId,
          isAccessoryOnlyOrder: createAccessoryOnlyOrder,
        },
      })
    }
  }, [
    treatmentDate,
    treatmentTime,
    addressId,
    checkDeliveryCharge,
    createAccessoryOnlyOrder,
  ])

  const [calculateOptimumShipDate, { data: shipData }] = useLazyQuery<
    OptimumShipDate,
    OptimumShipDateVariables
  >(OPTIMUM_SHIP_DATE_QUERY, { fetchPolicy: 'network-only' })

  const updateShippingData = (calibrationDate: string) => {
    if (treatmentDateOnly && treatmentTime && shipData) {
      const shippingTimeline = calculateShippingTimeline(
        treatmentDateOnly,
        treatmentTime,
        parseISO(shipData?.optimumShipDate.shipDate),
        countryConfiguration?.amTreatmentWindow
      )

      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          optimumShipDatesByCalDate: {
            ...prevFormData.optimumShipDatesByCalDate,
            [calibrationDate]: parseISO(shipData?.optimumShipDate.shipDate),
          },
          optimumShipDate: parseISO(shipData?.optimumShipDate.shipDate),
          shippingTimeline,
          showShippingError: shippingTimeline <= 3,
        }
      })
    }
  }

  useEffect(() => {
    if (
      treatmentDateOnly &&
      treatmentTime &&
      (createAccessoryOnlyOrder || selectedCalibrationDate)
    ) {
      calculateOptimumShipDate({
        variables: {
          treatmentDate: treatmentDateOnly,
          treatmentTime,
          selectedCalibrationDate,
          mnxRushOrder: isMnxTreatmentDate(),
        },
      })
    }
  }, [
    treatmentDateOnly,
    treatmentTime,
    selectedCalibrationDate,
    createAccessoryOnlyOrder,
  ])

  useEffect(() => {
    updateShippingData(selectedCalibrationDate)
  }, [
    treatmentDateOnly,
    treatmentTime,
    selectedCalibrationDate,
    shipData,
  ])

  useEffect(() => {
    if (user?.customer && !!treatmentDate) {
      const { purchaseOrder } = user?.customer

      if (!purchaseOrder || !purchaseOrder.reference) return

      const { startDate, endDate, reference } = purchaseOrder
      let poStartDate: Date | undefined
      let poEndDate: Date | undefined

      if (startDate) poStartDate = parseISO(startDate)
      if (endDate) poEndDate = parseISO(endDate)

      let valid = true
      const po_reference = !!poNumber ? poNumber : reference
      if (poStartDate && treatmentDate < poStartDate) valid = false
      if (poEndDate && treatmentDate > poEndDate) valid = false

      if (valid) {
        setFormData((prevFormData: OrderFormData) => {
          return {
            ...prevFormData,
            poNumber: po_reference,
            validBlanketPO: valid,
          }
        })
      } else if (!valid && poNumber === reference) {
        setFormData((prevFormData: OrderFormData) => {
          return {
            ...prevFormData,
            poNumber: '',
          }
        })
      }
    }
  }, [user, treatmentDate, selectedActivityVials])

  useEffect(() => {
    if (treatmentDateOnly && treatmentTime) {
      const siteTimezone = user?.preferredShippingAddress.timezone || ''
      if (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED) {
        const activity = totalActivityFocusedCalc(
          treatmentDateOnly,
          treatmentTime,
          selectedActivityVials
        )
        setFormData((prevFormData: OrderFormData) => {
          return { ...prevFormData, totalActivityAtTreatment: activity }
        })
      } else {
        const activity = totalActivityCalc(
          treatmentDateOnly,
          treatmentTime,
          selectedVials,
          siteTimezone
        )
        setFormData((prevFormData: OrderFormData) => {
          return { ...prevFormData, totalActivityAtTreatment: activity }
        })
      }
    }
  }, [
    selectedVials,
    treatmentTime,
    treatmentDateOnly,
    selectedActivityVials,
    formType,
  ])

  useEffect(() => {
    const vialSearchData = vialData?.vialSearch
    if (vialSearchData && selectedCalibrationDate) {
      const newSurplusData = {
        [selectedCalibrationDate]: vialSearchData.surplus,
      }
      setFormData((prevFormData: OrderFormData) => {
        const prevSurplusData = prevFormData.surplusData
        const surplusData = Object.assign(prevSurplusData, newSurplusData)
        return { ...prevFormData, surplusData }
      })
    }
  }, [vialData, selectedCalibrationDate])

  useEffect(() => {
    const address = selectedAddress(addressId, user)
    if (address) {
      const defaultAdminSet = address.defaultAdminSet || undefined
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          adminSets: defaultAdminSet ? prevFormData.adminSets : [],
          defaultAdminSet,
          address: address,
        }
      })
    }
  }, [addressId])

  const changeValue = (event: {
    currentTarget: { value: string; name: string }
  }): void => {
    const { value, name } = event.currentTarget

    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, [name]: value }
    })
  }

  const changeTreatmentDate = (date: Date | null): void => {
    setFormData((prevFormData: OrderFormData) => {
      return {
        ...prevFormData,
        surplusData: {},
        treatmentDate: date,
        showShippingError: false,
      }
    })
    setSelectedCalibrationDate('')
    clearSelectedVials()
    clearSelectedActivityVials()
  }

  const changeTreatmentTime = (option: Option | null): void => {
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentTime: option?.value }
    })
    clearSelectedVials()
    clearSelectedActivityVials()
  }

  const changePatientIntent = (option: Option | null): void => {
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentIntent: option?.value }
    })
  }

  const changeCalibrationDate = (calDate: string): void => {
    setSelectedCalibrationDate(calDate)
  }

  const poNumberPlaceholder = user?.customer.sapBillingDetail?.poRequired
    ? t('orderCreateWidget.poRequired', { ns: ['orderCreate'] })
    : t('orderCreateWidget.poOpt', { ns: ['orderCreate'] })

  const clearAdminSets = () => {
    // @ts-ignore
    setFormData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: [] }
    })
  }
  const clearTreatmentDate = () => {
    setFormData((prevData: OrderFormData) => {
      return { ...prevData, treatmentDate: null }
    })
  }

  const clearTreatmentTime = () => {
    setFormData((prevData: OrderFormData) => {
      return { ...prevData, treatmentTime: '' }
    })
  }

  const clearTreatingPhysician = () => {
    setFormData((prevData: OrderFormData) => {
      return {
        ...prevData,
        treatingPhysicianId: undefined,
        treatingPhysicianName: undefined,
      }
    })
  }

  const clearActivityFocusedFormDetails = () => {
    setFormData((prevData: OrderFormData) => {
      return {
        ...prevData,
        noOfVials: 0,
        desiredActivityAtTreatmentSet: Array<DesiredActivityAtTreatment>(),
        vialSearchResult: null,
      }
    })
  }

  const clearPatientIntent = () => {
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentIntent: null }
    })
  }

  const onChangeCheckBoxValue = (val: boolean) => {
    setCreateAccessoryOnlyOrder(val)
    if (val) {
      setFormType(OrderFormTypeEnum.VIAL_SELECTOR)
      setHideSwitchFormValue(true)
      clearSelectedActivityVials()
      clearActivityFocusedFormDetails()
      setPageTitle(
        t('orderCreateWidget.accessoryOrder', { ns: ['orderCreate'] })
      )
      setDateTitle(t('orderCreateWidget.targetDate', { ns: ['orderCreate'] }))
      setTimeTitle(t('orderCreateWidget.targetTime', { ns: ['orderCreate'] }))
      setDateTitlePlaceholder(
        t('orderCreateWidget.targetDatePlaceholder', { ns: ['orderCreate'] })
      )
      setTimeTitlePlaceholder(
        t('orderCreateWidget.targetTimePlaceholder', { ns: ['orderCreate'] })
      )
      setFormData((prevData: OrderFormData) => {
        return { ...prevData, showShippingError: false }
      })
      clearSelectedVials()
      clearTreatmentDate()
      clearTreatmentTime()
      clearPatientIntent()
      if (treatmentDate && treatmentTime && addressId) {
        checkDeliveryCharge({
          variables: {
            treatmentTime: treatmentTime,
            treatmentDate: treatmentDateOnly,
            addressId: addressId,
            isAccessoryOnlyOrder: createAccessoryOnlyOrder,
          },
        })
      }
    } else {
      setFormType(prevFormSource ?? OrderFormTypeEnum.VIAL_SELECTOR)
      setHideSwitchFormValue(false)
      setPageTitle(t('orderCreateWidget.vialSearch', { ns: ['orderCreate'] }))
      setDateTitle(t('orderCreateWidget.treatDate', { ns: ['orderCreate'] }))
      setTimeTitle(t('orderCreateWidget.treatTime', { ns: ['orderCreate'] }))
      setDateTitlePlaceholder(
        t('orderCreateWidget.treatDatePlaceholder', { ns: ['orderCreate'] })
      )
      setTimeTitlePlaceholder(
        t('orderCreateWidget.treatTimePlaceholder', { ns: ['orderCreate'] })
      )
      clearAdminSets()
      clearTreatmentDate()
      clearTreatmentTime()
      if (treatmentDate && treatmentTime && addressId) {
        checkDeliveryCharge({
          variables: {
            treatmentTime: treatmentTime,
            treatmentDate: treatmentDateOnly,
            addressId: addressId,
            isAccessoryOnlyOrder: createAccessoryOnlyOrder,
          },
        })
      }
      clearTreatingPhysician()
    }
  }

  const orderCreateButtonDisabled = (): boolean => {
    if (treatmentDateOnly && treatmentTime) {
      const adminSetsPresent = adminSets.some(adminSet => adminSet.confirmed)

      return !(
        (createAccessoryOnlyOrder && adminSetsPresent) ||
        selectedVials.size > 0
      )
    } else {
      return true
    }
  }

  const isMnxEligibleOrder = () => {
    return !createAccessoryOnlyOrder && isMnxEligibleAddress(addressId, user)
  }

  const isMedium = useMedia({ maxWidth: breakpoints[MEDIUM] })

  if (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED) {
    return (
      <OrderNewWidget
        formData={formData}
        setFormData={setFormData}
        onChangeCheckBoxValue={onChangeCheckBoxValue}
        createAccessoryOnlyOrder={createAccessoryOnlyOrder}
        changeTreatmentDate={changeTreatmentDate}
        minOrderTreatmentDate={minOrderTreatmentDate}
        isMnxEligibleOrder={isMnxEligibleOrder}
        isMnxTreatmentDate={isMnxTreatmentDate}
        deliveryChargeData={deliveryChargeData}
        customerData={customerData}
      />
    )
  }

  const jsxElement =
    validBlanketPO &&
    poNumber === user?.customer.purchaseOrder?.reference &&
    user?.customer.purchaseOrder?.endDate ? (
      <>
        <b>{t('orderCreateWidget.blanketPO', { ns: ['orderCreate'] })}</b>
        {formatDate(parseISO(user?.customer.purchaseOrder?.endDate))}
      </>
    ) : null

  return (
    <Grid
      gridGap="2rem"
      gridTemplateColumns={{ base: 'minmax(0, 1fr)', lg: '2fr 1fr' }}
    >
      <Container
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        height="100%"
        version={ThemeVersion.V2}
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          margin={{ base: '0 0 1rem 0', md: '0 1rem 0 0' }}
        >
          <BoxContainer1>
            <Heading>{pageTitle}</Heading>
            <Checkbox
              id="accessory-only-input-checkbox"
              label={t('orderCreateWidget.createAccessory', {
                ns: ['orderCreate'],
              })}
              defaultChecked={createAccessoryOnlyOrder}
              onChange={e => onChangeCheckBoxValue(e.target.checked)}
            />
          </BoxContainer1>

          <BoxContainer2>
            <ShippingAddress formData={formData} setFormData={setFormData} />
            <DatePickerWidget
              name="treatmentDate"
              label={dateTitle}
              id="treatment-date-input"
              placeholder={dateTitlePlaceholder}
              date={!!treatmentDate ? treatmentDate : undefined}
              onDateChange={changeTreatmentDate}
              minimumDate={minOrderTreatmentDate(isMnxEligibleOrder())}
              modifiers={{
                disabled: (date: Date) => {
                  return isDateDisabled(date, isMnxEligibleOrder())
                },
                highlight: (date: Date) => {
                  // do not highlight selected date
                  if (
                    treatmentDate &&
                    dateOnly(date) === dateOnly(treatmentDate)
                  ) {
                    return false
                  }
                  return mnxRushWindow(isMnxEligibleOrder())
                    .map(d => dateOnly(d))
                    .includes(dateOnly(date))
                },
              }}
            />
            <TreatmentTime
              label={timeTitle}
              placeholder={timeTitlePlaceholder}
              treatmentDate={treatmentDate}
              treatmentTime={treatmentTime}
              changeTreatmentTime={changeTreatmentTime}
              isMnxEligibleOrder={isMnxEligibleOrder}
              showShippingError={showShippingError}
            />
            <ShowExpeditedChargeMsg
              mnxRushOrder={isMnxTreatmentDate()}
              treatmentDate={treatmentDate}
              treatmentTime={treatmentTime}
              selectedCalibrationDate={selectedCalibrationDate}
              vials={getVialsIfParamsDefined(
                treatmentDate,
                treatmentTime,
                selectedCalibrationDate,
                vialData
              )}
              showShippingError={showShippingError}
              shippingTimeline={shippingTimeline}
              amTreatmentUnavailable={
                shipData?.optimumShipDate.amTreatmentUnavailable
              }
            />
            <InputV2
              label={t('orderCreateWidget.patientRefOpt', {
                ns: ['orderCreate'],
              })}
              aria-describedby="patient-ref-helper-text"
              id="patientRef"
              data-cy="patient-reference-input"
              name="patientRef"
              placeholder={t('orderCreateWidget.patientRefOpt', {
                ns: ['orderCreate'],
              })}
              hint={t('orderCreateWidget.includeNames', {
                ns: ['orderCreate'],
              })}
              onChange={changeValue}
              type="text"
              value={patientRef ?? ''}
            />
            <PatientIntent
              changePatientIntent={changePatientIntent}
              patientIntent={treatmentIntent}
            />
            {!createAccessoryOnlyOrder && displayReferringPhysician && (
              <ReferringPhysician
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {treatingPhysicianFeatureFlag && !createAccessoryOnlyOrder && (
              <TreatingPhysician
                formData={formData}
                setFormData={setFormData}
              />
            )}
            <InputV2
              label={t('orderCreateWidget.poOpt', { ns: ['orderCreate'] })}
              aria-describedby="po-number-helper-text"
              id="poNumber"
              data-cy="po-number-input"
              name="poNumber"
              placeholder={poNumberPlaceholder}
              onChange={changeValue}
              type="text"
              value={poNumber ?? ''}
              popoverHint={jsxElement}
            />

            {!createAccessoryOnlyOrder && (
              <TargetActivityAtTreatmentField
                formData={formData}
                setFormData={setFormData}
              />
            )}
          </BoxContainer2>
          {!createAccessoryOnlyOrder && (
            <Box marginTop="5rem">
              <CalibrationWeekToggle
                name="calibrationDate"
                treatmentDate={(treatmentTime && treatmentDate) || undefined}
                onInit={(calibrationDates: string[]) => {
                  // pre-calculate shipping date for each calibration date
                  // so that all data is availble for submitting form right after
                  // getting from the vials search page
                  for (let date of calibrationDates) updateShippingData(date)
                }}
                onClick={changeCalibrationDate}
                selectedValue={selectedCalibrationDate}
              />
            </Box>
          )}
        </Box>
        <Flex
          direction="column"
          justifyContent="space-between"
          width={{ base: '100%', md: '50%' }}
        >
          {!createAccessoryOnlyOrder ? (
            [undefined, true].includes(
              shipData?.optimumShipDate.fulfillable
            ) ? (
              <VialSearchResults
                vials={getVialsIfParamsDefined(
                  treatmentDate,
                  treatmentTime,
                  selectedCalibrationDate,
                  vialData
                )}
                loading={vialsLoading}
                maxHeight={{ base: '100%', md: '73rem' }}
                paddingTop="9.6rem"
              />
            ) : shipData?.optimumShipDate.amTreatmentUnavailable ? (
              <AmTreatmentUnfulfillable />
            ) : (
              <VialSearchUnfulfillable />
            )
          ) : (
            <div
              style={{
                maxWidth: '100%',
                margin: '96px 2rem',
                justifyContent: 'center',
                border: '#D8DDE2 1px solid',
                borderRadius: '10px',
                padding: '10rem 5rem 4.4rem 5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AccessoryIcon
                  fill={midnightBlue}
                  width="8rem"
                  height="10rem"
                />
                <WidgetHeader marginY="2rem">
                  {t('orderCreateWidget.tubingSets.title', {
                    ns: ['orderCreate'],
                  })}
                </WidgetHeader>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="2rem"
                  marginBottom={defaultAdminSet ? 0 : '3rem'}
                >
                  {defaultAdminSet && !isMnxTreatmentDate() ? (
                    <AccessoryFields
                      defaultAdminSet={defaultAdminSet}
                      adminSets={adminSets}
                      setAccessoryData={setFormData}
                      initializeWithAccessory={true}
                      version={ThemeVersion.V2}
                    />
                  ) : (
                    <>
                      {isMnxTreatmentDate() ? (
                        <div>
                          <Text color={red}>
                            <b>
                              {t('orderCreateWidget.accessorySets', {
                                ns: ['orderCreate'],
                              })}
                            </b>
                          </Text>
                        </div>
                      ) : (
                        <div>
                          <Text color={red}>
                            <b>
                              {t('orderCreateWidget.sorryNo', {
                                ns: ['orderCreate'],
                              })}
                            </b>
                          </Text>
                          <Text marginY="1rem">
                            <b>{customerSalesRep?.name}</b>
                          </Text>
                          <LinkButton
                            wordBreak="break-all"
                            padding="1.5rem"
                            href={`mailto:${customerSalesRep?.email}`}
                          >
                            <EmailIcon /> {customerSalesRep?.email}
                          </LinkButton>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </div>
            </div>
          )}

          {selectedCalibrationDate && shipData?.optimumShipDate.fulfillable && (
            <AddAccessoryButton
              adminSets={adminSets}
              defaultAdminSet={defaultAdminSet}
              setAccessoryData={setFormData}
              mnxRushOrder={isMnxTreatmentDate()}
              createAccessoryOnlyOrder={createAccessoryOnlyOrder}
            />
          )}
        </Flex>
      </Container>
      {isMedium && (
        <MediumContainer>
          <FormHeader>
            {t('orderCreateWidget.treatmentsAdded', { ns: ['orderCreate'] })}
          </FormHeader>
        </MediumContainer>
      )}
      <Container
        display="flex"
        flexDirection="column"
        height="100%"
        version={ThemeVersion.V2}
      >
        <Heading>
          {t('orderCreateWidget.treatmentsAdded', { ns: ['orderCreate'] })}
        </Heading>
        {createAccessoryOnlyOrder && (
          <InlineErrorMessage {...inlineErrorMessageStyleProps}>
            {t('orderCreateWidget.noTreatmentsRequired', {
              ns: ['orderCreate'],
            })}
          </InlineErrorMessage>
        )}
        <Flex
          direction="column"
          height="calc(100% - 6rem)"
          justifyContent="space-between"
        >
          <Box
            overflow="auto"
            maxHeight={{ base: '100%', md: '55%', lg: '90%' }}
            height="inherit"
            marginBottom={'2rem'}
          >
            <Treatments
              calibrationWeekData={calWeeksData}
              selectedVials={selectedVials}
              treatmentDate={
                !!treatmentDateOnly ? treatmentDateOnly : undefined
              }
              treatmentTime={treatmentTime}
              removeable
            />
            <ActivityAtTreatment
              selectedVials={selectedVials}
              targetActivity={targetActivityAtTreatment}
              totalActivity={totalActivityAtTreatment}
            />
            <Accessories
              accessories={adminSets}
              setFormData={setFormData}
              removeable
            />
          </Box>
          <OrderCreateButton
            mnxRushOrder={isMnxTreatmentDate()}
            disabled={orderCreateButtonDisabled()}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={withScreenStartTimeCheck(
              screenOpenedAt,
              isMnxEligibleOrder(),
              treatmentDate
            )}
            onSubmit={() =>
              withScreenStartTimeCheck(
                screenOpenedAt,
                isMnxEligibleOrder(),
                treatmentDate
              )(onOpen)
            }
            formData={formData}
            deliveryChargeData={deliveryChargeData?.checkDeliveryCharges}
            calibrationWeekData={calWeeksData}
            selectedVials={selectedVials}
            customer={customerData?.customer}
            createAccessoryOnlyOrder={createAccessoryOnlyOrder}
          />
        </Flex>
      </Container>
    </Grid>
  )
}
