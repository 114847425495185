import React, { useState, useEffect, useMemo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import {
  addDays,
  addBusinessDays,
  parseISO,
  isFriday,
  isWeekend,
} from 'date-fns'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { navigate, RouteComponentProps } from '@reach/router'
import pluralize from 'pluralize'
import {
  Button,
  CenteredWrapper,
  Container,
  Option,
} from '../../../components/Shared'
import { VialSearchResults, VialSearchUnfulfillable, AmTreatmentUnfulfillable } from './VialSearchResults'
import { DatePickerWidget } from '../../Forms'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { GET_CUSTOMER_QUERY } from './../../../schema/queries/CustomerQueries'
import {
  Notification,
  round,
  calcDecay,
  dateOnly,
  formattedTime,
  formatDate,
} from '../../../utils'
import { BoxIcon, PlusCircleIcon } from '../../Icons'
import {
  VIAL_SEARCH_QUERY,
  OPTIMUM_SHIP_DATE_QUERY,
} from '../../../schema/queries'
import {
  VialSearch,
  VialSearchVariables,
} from '../../../schema/queries/__generated__/VialSearch'
import { CalibrationWeekToggle } from './CalibrationWeekToggle'
import { ORDERS_CREATE_PATH } from '../../../routes'
import { themeUtils } from '../../../themeUtils'
import { useVialSearch } from '../../../hooks'
import {
  availableTreatmentTimes,
  isDateDisabled,
  isROWCustomer,
  minOrderTreatmentDate,
  minutesSinceMidnight,
  mnxRushWindow,
  timeZone,
  today,
  timeInTimeZone,
  getDefaultOrderFormType,
} from '../../lib'
import { OrderData } from '../../../types'
import { DATE_ISO_FORMAT } from '../../../constants'
import { ShowExpeditedChargeMsg } from '../../Shared/ShowExpeditedChargeMsg'
import { getVialsIfParamsDefined } from '../../../utils/getVialsIfParamsDefined'
import {
  OptimumShipDate,
  OptimumShipDateVariables,
} from '../../../schema/queries/__generated__/OptimumShipDate'
import { TreatmentTime } from '../../Orders'
import styled from '@emotion/styled'
import { useUser } from '../../../context/user-context'
import {
  AdminSetType,
  DesiredActivityAtTreatment,
  OrderFormData,
} from '../../Orders/OrderCreate/OrderFormDataTypes'
import { NoOfVialsField } from '../../Shared/VialSearch/NoOfVialsField'
import { OrderFormTypeEnum, SourceTypeEnum } from '../../../__generated__/globalTypes'
import { useForm } from 'react-hook-form'
import {
  DesiredActivityFieldSourceEnum,
  DesiredActivityFields,
} from '../../Shared/VialSearch/DesiredActivityFields'
import { Trans, useTranslation } from 'react-i18next'

export interface VialSearchState {
  treatmentDate: Date | null
  treatmentTime: string
  optimumShipDate: Date | undefined
  mnxRushOrder: boolean
  formType: OrderFormTypeEnum
}

type NavProps = RouteComponentProps<{ orderData: OrderData }>

const minutesThresholdForLateOrder = 1050 // 5.30pm Eastern time is 1050 minutes since midnight

const { green, midnightBlue } = themeUtils.colors

  // --- Business logic for setting order date ---
  // Monday to Friday before 7.30pm use now
  // Monday to Thursday after 7.30 use tomorrow 6am
  // Friday after 7.30 use Monday 6am
  // Sat / Sun any time use Monday 6am
  const getDefaultOrderDate = () => {
    const todayDateOnly = dateOnly(today())
    const todayTimeOnly = formattedTime(today(), 'HH:mm')
    const currentTimeMinutesSinceMidnight = minutesSinceMidnight(
      todayDateOnly,
      todayTimeOnly,
      timeZone()
    )
    const currentTimeAfterLateOrderThreshold = currentTimeMinutesSinceMidnight > minutesThresholdForLateOrder
    const todayIsWeekend = isWeekend(today())
    // Check whether today is Friday after the above threshold
    const todayIsFriday = isFriday(today())
    const fridayAfterThreshold = todayIsFriday && currentTimeAfterLateOrderThreshold
    if (todayIsWeekend || fridayAfterThreshold) {
      // Sat / Sun any time use Monday 6am
      return parseISO(
        `${dateOnly(addBusinessDays(today(), 1))} 06:00`
      )
    } else if (currentTimeAfterLateOrderThreshold) {
      // Monday to Thursday after 7.30 use tomorrow 6am, Fri / Sat / Sun would be caught above
      return parseISO(`${dateOnly(addDays(today(), 1))} 06:00`)
    } else {
      // All other times (before 7.30 on Monday to Thursday)
      return today()
    }
  }

const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const VialSearchWidget: React.FC<NavProps> = props => {
  const { t } = useTranslation(['dashboardScreen'])
  const user = useUser()
  let treatmentDate: Date | null = null

  const isMnxTreatmentDate = (): boolean => {
    if (!treatmentDate) return false
    return mnxRushWindow(isMnxEligibleCustomer())
      .map(d => dateOnly(d))
      .includes(dateOnly(treatmentDate))
  }

  const defaultOrderDate = getDefaultOrderDate()

  const [vialSearchData, setVialSearchData] = useState<VialSearchState>({
    treatmentDate: null,
    treatmentTime: '',
    optimumShipDate: undefined,
    mnxRushOrder: isMnxTreatmentDate(),
    formType: getDefaultOrderFormType(user),
  })

  const [formData, setFormData] = useState<OrderFormData>({
    treatmentDate: null,
    treatmentTime: '',
    patientRef: '',
    poNumber: '',
    validBlanketPO: false,
    optimumShipDate: undefined,
    optimumShipDatesByCalDate: {},
    targetActivityAtTreatment: undefined,
    totalActivityAtTreatment: 0,
    surplusData: {},
    shippingTimeline: undefined,
    showShippingError: false,
    addressId: '',
    address: user?.preferredShippingAddress || undefined,
    adminSets: Array<AdminSetType>(),
    defaultAdminSet:
      user?.preferredShippingAddress.defaultAdminSet || undefined,
    sourceType: SourceTypeEnum.THERASPHERE_NOW,
    treatingPhysicianId: '',
    treatingPhysicianName: '',
    noOfVials: 0,
    desiredActivityAtTreatmentSet: Array<DesiredActivityAtTreatment>(),
    vialSearchResult: null,
  })

  const { errors, clearError, setError } = useForm<OrderFormData>()

  const {
    selectedVials,
    selectedCalibrationDate,
    setSelectedCalibrationDate,
    clearSelectedVials,
  } = useVialSearch()

  const {
    treatmentTime,
    optimumShipDate,
    formType,
  } = vialSearchData

  const { noOfVials, desiredActivityAtTreatmentSet, surplusData, optimumShipDatesByCalDate } = formData

  treatmentDate = vialSearchData.treatmentDate

  const treatmentDateOnly = treatmentDate && dateOnly(treatmentDate)

  // @ts-ignore
  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY)

  const changeTreatmentDate = (date: Date | null): void => {
    setVialSearchData((prevData: VialSearchState) => {
      return { ...prevData, treatmentDate: date }
    })
    setFormData((prevFormData: OrderFormData) => {
      return {
        ...prevFormData,
        treatmentDate: date,
      }
    })
    setSelectedCalibrationDate('')
    clearSelectedVials()
  }

  const changeTreatmentTime = (option: Option | null): void => {
    setVialSearchData((prevData: VialSearchState) => {
      return { ...prevData, treatmentTime: option?.value }
    })
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentTime: option?.value }
    })
  }

  const changeCalDate = (calDate: string): void => {
    return setSelectedCalibrationDate(calDate)
  }

  const createNewOrder = () => {
    navigate(ORDERS_CREATE_PATH, {
      state: {
        orderData: {
          calibrationDate: selectedCalibrationDate,
          treatmentDate,
          treatmentTime,
          vials: selectedVials,
          formType,
          surplusData,
          optimumShipDatesByCalDate,
        },
      },
    })
  }

  const [searchVials, { data, loading, error }] = useLazyQuery<
    VialSearch,
    VialSearchVariables
  >(VIAL_SEARCH_QUERY, { fetchPolicy: 'network-only' })

  const [calculateOptimumShipDate, { data: shipData }] = useLazyQuery<
    OptimumShipDate,
    OptimumShipDateVariables
  >(OPTIMUM_SHIP_DATE_QUERY, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (treatmentDateOnly && treatmentTime && selectedCalibrationDate) {
      calculateOptimumShipDate({
        variables: {
          treatmentDate: treatmentDateOnly,
          treatmentTime,
          selectedCalibrationDate,
        },
      })
    }
  }, [
    treatmentDateOnly,
    treatmentTime,
    selectedCalibrationDate,
  ])

  useEffect(() => {
    if (shipData?.optimumShipDate.shipDate) {
      let shipDate = parseISO(shipData?.optimumShipDate.shipDate)

      if (isMnxTreatmentDate()) {
        shipDate = new Date()
      }

      setVialSearchData((prevData: VialSearchState) => {
        return { ...prevData, optimumShipDate: shipDate }
      })

      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          optimumShipDatesByCalDate: {
            ...prevFormData.optimumShipDatesByCalDate,
            [selectedCalibrationDate]: shipDate,
          },
        }
      })
    }
  }, [shipData])

  useEffect(() => {
    if (
      treatmentDate &&
      treatmentTime &&
      selectedCalibrationDate &&
      optimumShipDate
    ) {
      searchVials({
        variables: {
          calibrationDate: selectedCalibrationDate,
          treatmentDate: treatmentDateOnly,
          treatmentTime: treatmentTime,
          optimumShipDate: formatDate(optimumShipDate, DATE_ISO_FORMAT),
          mnxRushOrder: isMnxTreatmentDate(),
        },
      })
    }
  }, [
    selectedCalibrationDate,
    treatmentDate,
    treatmentTime,
    optimumShipDate,
    searchVials,
  ])

  useEffect(() => {
    const vialSearchData = data?.vialSearch
    if (vialSearchData && selectedCalibrationDate) {
      const newSurplusData = {
        [selectedCalibrationDate]: vialSearchData.surplus,
      }
      setFormData((prevFormData: OrderFormData) => {
        const prevSurplusData = prevFormData.surplusData
        const surplusData = Object.assign(prevSurplusData, newSurplusData)
        return { ...prevFormData, surplusData }
      })
    }
  }, [data, selectedCalibrationDate])

  if (error) {
    Notification.error(error.message)
  }

  const isMnxEligibleCustomer = () => {
    return Boolean(customerData?.customer?.mnxEligibleCustomer)
  }

  const selectedVialQuantity = Array.from(selectedVials.values()).reduce(
    (quantity, selectedVials) => {
      let count = 0
      selectedVials.forEach(vial => (count += vial))

      return quantity + count
    },
    0
  )


  let totalActivity = 0
  const totalActivityCalc = useMemo(() => {
    if (!!treatmentDateOnly && !!treatmentTime) {
      const splitTreatmentTime = treatmentTime.split(':')
      const siteTimezone = user?.preferredShippingAddress.timezone || ''
      const treatmentDateTime = timeInTimeZone(parseISO(treatmentDateOnly), Number(splitTreatmentTime[0]), Number(splitTreatmentTime[1]), 0, siteTimezone)
      Array.from(selectedVials.entries()).map(([dosage, vials]) => {
        vials.forEach((quantity, calibrationDate) => {
          const decay = calcDecay(
            dosage,
            calibrationDate,
            treatmentDateOnly,
            treatmentTime,
            treatmentDateTime
          )
          totalActivity += decay * quantity
        })
      })

      return round(totalActivity, 1)
    }
    return totalActivity
  }, [treatmentDateOnly, selectedVials, treatmentTime])

  if (formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED) {
    const handleSubmit = (): void => {
      //Verify if there are errors
      const hasErrors = Object.values(errors).some(obj => obj)

      if (
        !hasErrors &&
        desiredActivityAtTreatmentSet &&
        treatmentDate
      ) {
        navigate(ORDERS_CREATE_PATH, {
          state: {
            orderData: {
              treatmentDate,
              treatmentTime,
              noOfVials,
              desiredActivityAtTreatmentSet,
              formType,
            },
          },
        })
      }
    }

    return (
      <Container
        heading={t('treatmentActivity.focusedSearch', { ns: ['dashboardScreen'] })}
        height={{ base: '100%', md: '55rem', lg: '100%' }}
      >
        <Flex direction={{ base: 'column', md: 'row' }} height="100%">
          <Box
            width={{ base: '100%', md: '50%' }}
            margin={{ base: '0 0 2rem 0', md: '0 2rem 0 0' }}
          >
            <BoxContainer marginBottom={{ base: '0rem', md: '5rem' }}>
              <DatePickerWidget
                name="treatmentDate"
                label={t('treatmentDateInput.label', { ns: ['dashboardScreen'] })}
                id="treatment-date-input"
                placeholder={t('treatmentDateInput.placeholder', { ns: ['dashboardScreen'] })}
                onDateChange={changeTreatmentDate}
                minimumDate={minOrderTreatmentDate(isMnxEligibleCustomer())}
                modifiers={{
                  disabled: (date: Date) => {
                    return isDateDisabled(date, isMnxEligibleCustomer())
                  },
                  highlight: (date: Date) => {
                    // do not highlight selected date
                    if (
                      treatmentDate &&
                      dateOnly(date) === dateOnly(treatmentDate)
                    ) {
                      return false
                    }
                    return mnxRushWindow(isMnxEligibleCustomer())
                      .map(d => dateOnly(d))
                      .includes(dateOnly(date))
                  },
                }}
                date={!!treatmentDate ? treatmentDate : undefined}
              />
              <TreatmentTime
                label={t('treatmentTimeInput.label', { ns: ['dashboardScreen'] })}
                placeholder={t('treatmentTimeInput.placeholder', { ns: ['dashboardScreen'] })}
                treatmentDate={treatmentDate}
                treatmentTime={treatmentTime}
                changeTreatmentTime={changeTreatmentTime}
                isMnxEligibleOrder={isMnxEligibleCustomer}
              />
              <div>
                <NoOfVialsField
                  noOfVials={noOfVials ?? 0}
                  desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
                  setFormData={setFormData}
                  errors={errors}
                  setError={setError}
                  isReadOnly={false}
                  isDisabled={!treatmentDate || !treatmentTime}
                />
              </div>
            </BoxContainer>
          </Box>
          <Flex
            direction="column"
            justifyContent="space-between"
            width={{ base: '100%', md: '50%' }}
            gridGap={'2rem'}
          >
            {!noOfVials && (
              <CenteredWrapper
                height="100%"
                padding={{ base: '2rem 0 0 0', md: '6.2rem 0 0 0' }}
              >
                <BoxIcon />
                <Text marginTop="1.5rem" textAlign="center">
                  {t('treatmentActivity.placeholder.text', { ns: ['dashboardScreen'] })}
                </Text>
              </CenteredWrapper>
            )}

            <DesiredActivityFields
              noOfVials={noOfVials ?? 0}
              desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
              setFormData={setFormData}
              errors={errors}
              isReadOnly={false}
              source={DesiredActivityFieldSourceEnum.DASHBOARD_SEARCH}
            />
            {noOfVials && noOfVials > 0 ? (
              <Button
                id="activity-at-treatment-submit-btn"
                data-cy="dashboard-widget-create-order-btn"
                backgroundColor={green}
                isFullWidth
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={
                  !desiredActivityAtTreatmentSet?.every(s => s.activity) ||
                  noOfVials === 0
                }
              >
                {t('treatmentActivity.button.text', { ns: ['dashboardScreen'] })}
              </Button>
            ) : null}
          </Flex>
        </Flex>
      </Container>
    )
  }

  return (
    <Container
      heading={t('vialWidget.headerText', { ns: ['dashboardScreen'] })}
      height={{ base: '100%', md: '55rem', lg: '100%' }}
    >
      <Flex direction={{ base: 'column', md: 'row' }} height="100%">
        <Box
          width={{ base: '100%', md: '50%' }}
          margin={{ base: '0 0 1rem 0', md: '0 1rem 0 0' }}
        >
          <BoxContainer marginBottom="5rem">
            <DatePickerWidget
              name="treatmentDate"
              label={t('treatmentDateInput.label', { ns: ['dashboardScreen'] })}
              id="treatment-date-input"
              placeholder={t('treatmentDateInput.placeholder', {
                ns: ['dashboardScreen'],
              })}
              onDateChange={changeTreatmentDate}
              minimumDate={minOrderTreatmentDate(isMnxEligibleCustomer())}
              modifiers={{
                disabled: (date: Date) => {
                  return isDateDisabled(date, isMnxEligibleCustomer())
                },
                highlight: (date: Date) => {
                  // do not highlight selected date
                  if (
                    treatmentDate &&
                    dateOnly(date) === dateOnly(treatmentDate)
                  ) {
                    return false
                  }
                  return mnxRushWindow(isMnxEligibleCustomer())
                    .map(d => dateOnly(d))
                    .includes(dateOnly(date))
                },
              }}
              date={!!treatmentDate ? treatmentDate : undefined}
            />
            <TreatmentTime
              label={t('treatmentTimeInput.label', { ns: ['dashboardScreen'] })}
              placeholder={t('treatmentTimeInput.placeholder', {
                ns: ['dashboardScreen'],
              })}
              treatmentDate={treatmentDate}
              treatmentTime={treatmentTime}
              changeTreatmentTime={changeTreatmentTime}
              isMnxEligibleOrder={isMnxEligibleCustomer}
            />
            <ShowExpeditedChargeMsg
              mnxRushOrder={isMnxTreatmentDate()}
              treatmentDate={treatmentDate}
              treatmentTime={treatmentTime}
              selectedCalibrationDate={selectedCalibrationDate}
              vials={getVialsIfParamsDefined(
                treatmentDate,
                treatmentTime,
                selectedCalibrationDate,
                data
              )}
              amTreatmentUnavailable={
                shipData?.optimumShipDate.amTreatmentUnavailable
              }
            />
          </BoxContainer>
          <CalibrationWeekToggle
            treatmentDate={(treatmentTime && treatmentDate) || undefined}
            onClick={changeCalDate}
            selectedValue={selectedCalibrationDate}
          />
          {selectedVials.size > 0 && (
            <Text color={midnightBlue} marginTop="2rem">
              <Trans
                i18nKey="vialWidget.totalActivity"
                ns="dashboardScreen"
                values={{ totalActivityCalc }}
                components={{ b: <b /> }}
              />
            </Text>
          )}
        </Box>
        <Flex
          direction="column"
          justifyContent="space-between"
          width={{ base: '100%', md: '50%' }}
        >
          {[undefined, true].includes(shipData?.optimumShipDate.fulfillable) ? (
            <VialSearchResults
              vials={getVialsIfParamsDefined(
                treatmentDate,
                treatmentTime,
                selectedCalibrationDate,
                data
              )}
              loading={loading}
              paddingTop=""
            />
          ) : shipData?.optimumShipDate.amTreatmentUnavailable ? (
            <AmTreatmentUnfulfillable />
          ) : (
            <VialSearchUnfulfillable />
          )}
          {selectedCalibrationDate && shipData?.optimumShipDate.fulfillable && (
            <Button
              backgroundColor={green}
              marginTop="1rem"
              height="6rem"
              paddingX="2rem"
              paddingY="2rem"
              flexShrink={0}
              justifyContent="space-between"
              onClick={createNewOrder}
              isDisabled={selectedVials.size === 0}
              rightIcon={PlusCircleIcon}
              data-cy="dashboard-widget-create-order-btn"
            >
              {t('vialWidget.createOrder', {
                selectedVialQuantity,
                vials: pluralize('vial', selectedVialQuantity),
                ns: ['dashboardScreen'],
              })}
            </Button>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}
