/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CustomerBusinessTypeEnum {
  CustomerViaDistributor = "CustomerViaDistributor",
  DirectCustomer = "DirectCustomer",
  Distributor = "Distributor",
}

export enum CustomerCareCutoffTypeEnum {
  NA = "NA",
  ROW = "ROW",
  TURKEY = "TURKEY",
}

export enum EnvironmentEnum {
  snd = "snd",
  staging = "staging",
}

export enum ErrorTypes {
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  AUTHORISATION_ERROR = "AUTHORISATION_ERROR",
  BUILD_PLAN_STATUS_CHANGE_ERROR = "BUILD_PLAN_STATUS_CHANGE_ERROR",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  INVALID_STATE = "INVALID_STATE",
  ORDER_DATE_MISMATCH_ERROR = "ORDER_DATE_MISMATCH_ERROR",
  UNABLE_TO_CANCEL = "UNABLE_TO_CANCEL",
  UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_ACCESSORIES = "UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_ACCESSORIES",
  UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_JURISDICTION = "UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_JURISDICTION",
  UNABLE_TO_UPDATE = "UNABLE_TO_UPDATE",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  VALIDATION_ORDER_BASE = "VALIDATION_ORDER_BASE",
}

export enum OrderCreditTypeEnum {
  CREDIT_AND_REBILL = "CREDIT_AND_REBILL",
  FULL_CREDIT = "FULL_CREDIT",
}

export enum OrderFinanceStatusEnum {
  ACCEPTED_BY_FINANCE = "ACCEPTED_BY_FINANCE",
  ACCEPTED_BY_FINANCE_WITH_WARNINGS = "ACCEPTED_BY_FINANCE_WITH_WARNINGS",
  ALL = "ALL",
  CANCEL_CREDIT_REQUEST = "CANCEL_CREDIT_REQUEST",
  CREDITED = "CREDITED",
  CREDITED_IN_S4 = "CREDITED_IN_S4",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CREDIT_BLOCK_REMOVED = "CREDIT_BLOCK_REMOVED",
  CREDIT_BLOCK_REMOVED_IN_S4 = "CREDIT_BLOCK_REMOVED_IN_S4",
  CREDIT_CHECK_FAILED = "CREDIT_CHECK_FAILED",
  CREDIT_CHECK_FAILED_IN_S4 = "CREDIT_CHECK_FAILED_IN_S4",
  CREDIT_CHECK_INCOMPLETE = "CREDIT_CHECK_INCOMPLETE",
  CREDIT_CHECK_INCOMPLETE_IN_S4 = "CREDIT_CHECK_INCOMPLETE_IN_S4",
  CREDIT_CHECK_INITIATED = "CREDIT_CHECK_INITIATED",
  CREDIT_CHECK_INITIATED_IN_S4 = "CREDIT_CHECK_INITIATED_IN_S4",
  CREDIT_CHECK_SUCCESSFUL = "CREDIT_CHECK_SUCCESSFUL",
  CREDIT_CHECK_SUCCESSFUL_IN_S4 = "CREDIT_CHECK_SUCCESSFUL_IN_S4",
  CREDIT_CHECK_WARNING = "CREDIT_CHECK_WARNING",
  CREDIT_REJECTED_BY_FINANCE = "CREDIT_REJECTED_BY_FINANCE",
  CREDIT_REQUESTED = "CREDIT_REQUESTED",
  CREDIT_REQUESTED_IN_S4 = "CREDIT_REQUESTED_IN_S4",
  DEBITED_IN_S4 = "DEBITED_IN_S4",
  DEBIT_REQUESTED_IN_S4 = "DEBIT_REQUESTED_IN_S4",
  INVOICED = "INVOICED",
  NONE = "NONE",
  ORDER_CANCELLED_IN_S4 = "ORDER_CANCELLED_IN_S4",
  ORDER_CREATED_IN_S4 = "ORDER_CREATED_IN_S4",
  ORDER_CREATION_FAILED_IN_S4 = "ORDER_CREATION_FAILED_IN_S4",
  ORDER_UPDATE_FAILED_IN_S4 = "ORDER_UPDATE_FAILED_IN_S4",
  ORDER_UPDATE_SENT_TO_S4 = "ORDER_UPDATE_SENT_TO_S4",
  ORDER_UPDATE_SUCCESSFUL_IN_S4 = "ORDER_UPDATE_SUCCESSFUL_IN_S4",
  PAID = "PAID",
  PENDING_PO = "PENDING_PO",
  REJECTED_BY_FINANCE = "REJECTED_BY_FINANCE",
  SENT_TO_FINANCE = "SENT_TO_FINANCE",
  SENT_TO_S4 = "SENT_TO_S4",
}

export enum OrderFormTypeEnum {
  ACTIVITY_AT_TREATMENT_FOCUSED = "ACTIVITY_AT_TREATMENT_FOCUSED",
  VIAL_SELECTOR = "VIAL_SELECTOR",
}

export enum OrderPortalStatusEnum {
  CANCELLED = "CANCELLED",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  SHIPPED = "SHIPPED",
  SUBMITTED = "SUBMITTED",
}

export enum OrderStateEnum {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum OrderStatusEnum {
  ACCEPTED = "ACCEPTED",
  ALL = "ALL",
  BUILD_PENDING = "BUILD_PENDING",
  BUILT = "BUILT",
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  DRAFT = "DRAFT",
  NEW = "NEW",
  REJECTED = "REJECTED",
  RELEASED = "RELEASED",
  RESERVED = "RESERVED",
  SHIPPED = "SHIPPED",
  VOID = "VOID",
}

export enum ReasonTypeEnum {
  CANCELLATION = "CANCELLATION",
  CREDIT = "CREDIT",
  SAME_DAY_SHIPPING = "SAME_DAY_SHIPPING",
  VOID = "VOID",
}

export enum RegionCodeEnum {
  ALL = "ALL",
  CA = "CA",
  ROW = "ROW",
  US = "US",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SourceTypeEnum {
  EMAIL_SETS = "EMAIL_SETS",
  IDOC = "IDOC",
  ORDER_FORM_EMAIL = "ORDER_FORM_EMAIL",
  ORDER_FORM_FAX = "ORDER_FORM_FAX",
  THERASPHERE360 = "THERASPHERE360",
  THERASPHERE_NOW = "THERASPHERE_NOW",
  TO_FOLLOW = "TO_FOLLOW",
  WEB_PORTAL = "WEB_PORTAL",
}

export enum TeamMemberProfessionEnum {
  administrative_staff = "administrative_staff",
  finance_supply_chain = "finance_supply_chain",
  nuclear_medicine_technologist = "nuclear_medicine_technologist",
  other = "other",
  physicist = "physicist",
  radiation_safety_officer = "radiation_safety_officer",
  radiopharmacist = "radiopharmacist",
  referring_physician = "referring_physician",
  treating_physician = "treating_physician",
}

export enum TreatmentFreeReasonsEnum {
  A = "A",
  B = "B",
  C = "C",
  X = "X",
}

export enum TreatmentIntentEnum {
  HCC = "HCC",
  MCRC = "MCRC",
  OTHER = "OTHER",
}

export interface AccessoryInput {
  id?: string | null;
  productId: string;
  quantity: number;
  lineItemNumber: any;
}

export interface CancelOrderInput {
  id: string;
  cancellationReasonId: string;
}

export interface CreateOrderInput {
  mnxRushOrder?: boolean | null;
  orderDate?: any | null;
  treatmentDate: any;
  treatmentTime: string;
  shipDate: any;
  patientRef?: string | null;
  poNumber?: string | null;
  treatments: TreatmentInput[];
  accessories?: AccessoryInput[] | null;
  addressId?: string | null;
  shippingNote?: string | null;
  useBwxtSurplusInventory?: boolean | null;
  useMnxSurplusInventory?: boolean | null;
  sourceType?: SourceTypeEnum | null;
  treatingPhysicianName?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  treatmentIntent?: TreatmentIntentEnum | null;
  treatmentId?: string | null;
}

export interface CreateTeamMemberInput {
  firstName: string;
  lastName: string;
  email?: string | null;
  profession: TeamMemberProfessionEnum;
  otherProfession?: string | null;
  npi?: string | null;
  customerId: string;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface NewPasswordInput {
  token: string;
  password: string;
  passwordConfirmation: string;
}

export interface OrderFiltersInput {
  state: OrderStateEnum;
  page: number;
  sort: SortInput;
  limit: number;
  search: string;
  status?: OrderPortalStatusEnum | null;
  customerId?: string | null;
}

export interface PasswordResetInput {
  email: string;
}

export interface SortInput {
  column: string;
  direction: SortDirectionEnum;
}

export interface TreatmentInput {
  dosage: number;
  quantity: number;
  calibrationDate: any;
  lineItemNumber: any;
  freeReason?: TreatmentFreeReasonsEnum | null;
}

export interface UpdateOrderInput {
  id: string;
  poNumber?: string | null;
  patientRef?: string | null;
  treatingPhysicianName?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  treatmentId?: string | null;
}

export interface UpdatePoNumberInput {
  id: string;
  poNumber: string;
}

export interface UpdatePortalUserInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  hasUpdatedDetails?: boolean | null;
  teamMemberAttributes: UpdateTeamMemberInput;
  defaultOrderForm?: OrderFormTypeEnum | null;
}

export interface UpdateReferringPhysicianInput {
  id: string;
  referringPhysicianId?: string | null;
  referringPhysicianName?: string | null;
}

export interface UpdateTeamMemberInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  profession?: TeamMemberProfessionEnum | null;
  otherProfession?: string | null;
  npi?: string | null;
  removalRequestedAt?: any | null;
}

export interface UpdateTreatingPhysicianInput {
  id: string;
  treatingPhysicianId?: string | null;
  treatingPhysicianName?: string | null;
}

export interface UpdateTreatmentIntentInput {
  id: string;
  treatmentIntent?: TreatmentIntentEnum | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
