import {
  addBusinessDays,
  addDays,
  differenceInMinutes,
  getDay,
  isBefore,
  isSameWeek,
  isWeekend,
  lastDayOfWeek,
  parseISO,
  subBusinessDays,
} from 'date-fns'
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  dateOnly,
  calcDecay,
  round,
  formattedTime,
  newDate,
  Notification
} from '../../utils'
import { ActivityVialMapType, VialMapType } from '../../context/VialSearchContext'
import {
  GetMyPortalUser_me_shippingAddresses,
  GetMyPortalUser_me_preferredShippingAddress,
  GetMyPortalUser_me,
} from '../../context/__generated__/GetMyPortalUser'
import { OrderShow_order_treatments } from '../../schema/queries/__generated__/OrderShow'
import { treatmentTimes } from '../Shared/VialSearch/TreatmentTimes'
import { COUNTRY_CONFIGURATION } from '../../schema/queries'
import { client } from '../../schema/apollo-client'
import moment from 'moment'
import { OrderFormTypeEnum } from '../../__generated__/globalTypes'
import { isROWCustomer } from './customers'

export let countryConfiguration: any = undefined
let countryConfigurationLoading = false

const getCountryConfiguration = (): any => {
  if (countryConfiguration || countryConfigurationLoading) return
  countryConfigurationLoading = true
  client.query({
    query: COUNTRY_CONFIGURATION,
    fetchPolicy: 'cache-first'
  })
  .then(data => {
    countryConfiguration = data.data.countryConfiguration
    countryConfigurationLoading = data.loading
  })
}

const usMnx = (isMnxEligible: boolean): boolean => {
  return isMnxEligible && ['US', undefined].includes(countryConfiguration?.countryCode)
}

export const ccTimeZone = (): string => { return countryConfiguration?.timezone }
export const timeZone = (): string => { return 'America/Toronto' }

export const minutesSinceMidnight = (
  date: string,
  time: string,
  timeZone?: string
): number => {
  const parsedDateTime = parseISO(`${date} ${time}`)
  const parsedDateTimeMidnight = parseISO(`${date} 00:00`)
  let dateTime = parsedDateTime
  let dateTimeMidnight = parsedDateTimeMidnight
  if (timeZone) {
    dateTime = utcToZonedTime(dateTime, timeZone)
    dateTimeMidnight = utcToZonedTime(parsedDateTimeMidnight, timeZone)
  }
  return differenceInMinutes(dateTime, dateTimeMidnight)
}

export const totalActivityCalc = (
  treatmentDate: string,
  treatmentTime: string,
  treatments: VialMapType | OrderShow_order_treatments[],
  siteTimezone: string
): number => {
  if (!treatmentDate) return 0

  const splitTreatmentTime = treatmentTime.split(':')
  const treatmentDateTime = timeInTimeZone(parseISO(treatmentDate), Number(splitTreatmentTime[0]), Number(splitTreatmentTime[1]), 0, siteTimezone)

  let totalActivity = 0
  if (treatments instanceof Map) {
    Array.from(treatments.entries()).map(([dosage, vials]) => {
      vials.forEach((quantity, calibrationDate) => {
        const decay = calcDecay(
          dosage,
          calibrationDate,
          treatmentDate,
          treatmentTime,
          treatmentDateTime,
        )

        return (totalActivity += decay * quantity)
      })
      return totalActivity
    })
  } else {
    treatments.forEach(treatment => {
      if (!treatment.product.dosage) return 0

      const decay = calcDecay(
        treatment.product.dosage,
        treatment.calibrationDate,
        treatmentDate,
        treatmentTime,
        treatmentDateTime,
      )

      return (totalActivity += decay * treatment.quantity)
    })
  }

  return round(totalActivity, 2)
}

export const totalActivityFocusedCalc = (
  treatmentDate: string,
  treatmentTime: string,
  treatments: ActivityVialMapType | OrderShow_order_treatments[]
): number => {
  if (!treatmentDate) return 0

  let totalActivity = 0
  if (treatments instanceof Map) {
    treatments.forEach(activityVial => {
      activityVial.forEach(vial => {
        if (typeof vial.treatment_activity === 'number') {
          totalActivity += vial.treatment_activity
        }
      })
    })
    return totalActivity
  } else {
    treatments.forEach(treatment => {
      if (!treatment.product.dosage) return 0

      const decay = calcDecay(
        treatment.product.dosage,
        treatment.calibrationDate,
        treatmentDate,
        treatmentTime
      )

      return (totalActivity += decay * treatment.quantity)
    })
  }

  return round(totalActivity, 2)
}

export const addressLabel = (
  address:
    | GetMyPortalUser_me_shippingAddresses
    | GetMyPortalUser_me_preferredShippingAddress
): string => {
  const { line1, line2, line3, city, state, zip, country } = address
  let attributes = [line1, line2, line3, city, state, zip, country]
    .filter(Boolean)
    .join(', ')

  if (address.nordionShipToId) {
    attributes = `${address.nordionShipToId} - ${attributes}`
  }

  return attributes
}

const dateInTimeZone = (date: Date, zone: string): Date => {
  const utc = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
  return utcToZonedTime(utc, zone)
}

export const timeInTimeZone = (date: Date = ccToday(), h: number = 0, m: number = 0, s: number = 0, zone: string = ccTimeZone()): Date => {
  const offset = getTimezoneOffset(zone) - getTimezoneOffset(moment.tz.guess())
  const utc = date.setHours(h, m, s).valueOf() - offset
  return new Date(utc)
}

export const ccToday = () => dateInTimeZone(new Date(), ccTimeZone())
export const today = () => dateInTimeZone(new Date(), 'America/Toronto')

const thisWeekMonday = () => {
  const d = new Date(ccToday())
  const day = d.getDay()
  const diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
  return dateInTimeZone(new Date(ccToday().setDate(diff)), timeZone())
}

const nextWeekMonday = (): Date => {
  return addBusinessDays(thisWeekMonday(), 5)
}

const dateAt = (date: Date, h: number = 0, m: number = 0, s: number = 0): Date => {
  date.setHours(h, m, s)
  return date
}

export const todayMnxCutOffDateTime = () => {
  return timeInTimeZone(new Date(), 17, 30, 0, 'America/Toronto')
}

const isTodayWorkDayBeforeCutOffDateTime = (isMnxEligible: boolean): boolean => {
  getCountryConfiguration()
  return usMnx(isMnxEligible) ? !isWeekend(new Date()) && (new Date() < todayMnxCutOffDateTime()) : !isWeekend(new Date()) && new Date() < cutOffTimeToday()
}

const cutOffTimeToday = (): Date => { return timeInTimeZone(...[new Date()].concat(countryConfiguration?.cutOffTime?.split(':'))) }

export const minOrderTreatmentDate = (isMnxEligible: boolean): Date => {
  const useMnx = usMnx(isMnxEligible)
  if (useMnx) return mnxRushWindow(useMnx)[0]
  getCountryConfiguration()
  let bufferTreatmentDate = addDays(addBusinessDays(ccToday(), 1), (countryConfiguration?.minimumBufferDays || 0) + 1)

  if (isWeekend(ccToday())) {
    const nextWeekDate = addBusinessDays(nextWeekMonday(), 3)
    return nextWeekDate > bufferTreatmentDate ? nextWeekDate : bufferTreatmentDate
  } else {
    const day = ccToday().getDay()
    let days
    const beforeCutOff = isTodayWorkDayBeforeCutOffDateTime(useMnx)
    if ([1, 2].includes(day)) {
      days = beforeCutOff ? 3 : 4
    } else if ([3].includes(day)) {
      days = beforeCutOff ? 3 : 5
    } else if ([4].includes(day)) {
      days = beforeCutOff ? 4 : 6
    } else {
      days = beforeCutOff ? 5 : 6
    }
    const treatmentDate = addDays(ccToday(), days)
    if (!beforeCutOff) bufferTreatmentDate = addDays(bufferTreatmentDate, 1)
    return treatmentDate > bufferTreatmentDate ? treatmentDate : bufferTreatmentDate
  }
}

export const minOrderTreatmentTime = (date: Date, isMnxEligible: boolean): string => {
  let time = '06:00 AM'
  const useMnx = usMnx(isMnxEligible)
  if (dateOnly(date) === dateOnly(minOrderTreatmentDate(useMnx)) && useMnx) time = '12:00 PM'
  return time
}

const isDateWithinRushWindow = (date: Date | null, isMnxEligible: boolean, onDateTime: Date | null = null): boolean => {
  getCountryConfiguration()
  const useMnx = usMnx(isMnxEligible)
  if (!date || !useMnx) return false

  const rushDays = mnxRushWindow(useMnx, onDateTime)
  const satisfiesRushWindowTime = onDateTime ? true : isWithinRushTimeWindow()
  return satisfiesRushWindowTime && rushDays.map(d => dateOnly(d)).includes(dateOnly(date))
}

const mnxOrderDate = (treatmentDate: Date | null, isMnxEligible: boolean, site_midday_today: Date): Date => {
  getCountryConfiguration()
  if (isDateWithinRushWindow(treatmentDate, usMnx(isMnxEligible)) || (isWeekend(new Date()) || new Date() >= cutOffTimeToday())) {
    return dateAt(addBusinessDays(site_midday_today, 1), 6)
  } else {
    return site_midday_today
  }
}

export const isDateDisabled = (date: Date, isMnxEligible: boolean): boolean => {
  getCountryConfiguration()
  if (!isWeekend(date)) return false

  const isOnThisWeek = dateOnly(date) < dateOnly(nextWeekMonday())
  const isTodayAfterWed = ccToday().getDay() > 3

  let isTodayWedAfterCutOffTime
  if (usMnx(isMnxEligible)) {
    isTodayWedAfterCutOffTime = ccToday().getDay() === 3 && ccToday() > todayMnxCutOffDateTime()
  } else {
    isTodayWedAfterCutOffTime = ccToday().getDay() === 3 && countryConfiguration?.cut_off_passed
  }
  return isOnThisWeek && (isTodayWedAfterCutOffTime || isTodayAfterWed)
}

const isWithinRushTimeWindow = (onDateTime: Date | null = null) => {
  const at = onDateTime || new Date()
  getCountryConfiguration()
  return todayMnxCutOffDateTime() <= at && at < cutOffTimeToday()
}

export const mnxRushWindow = (isMnxEligible: boolean, onDateTime: Date | null = null) => {
  getCountryConfiguration()
  if (!usMnx(isMnxEligible)) return []
  const at = onDateTime || new Date()

  if (isWeekend(at) || at >= cutOffTimeToday()) {
    return [addBusinessDays(at, 2), addBusinessDays(at, 3)]
  } else if (isWithinRushTimeWindow(at)) {
    return [addBusinessDays(at, 2)]
  } else {
    return [addBusinessDays(at, 1), addBusinessDays(at, 2)]
  }
}

export const availableTreatmentTimes = (treatmentDate: Date | undefined | null, isMnxEligible: boolean) => {
  if (!treatmentDate) return []
  getCountryConfiguration()

  const minTreatmentTime = minOrderTreatmentTime(treatmentDate, usMnx(isMnxEligible))
  const filtered = treatmentTimes().filter(({label, value}) => Date.parse(`01/01/2011 ${value}`) >= Date.parse(`01/01/2011 ${minTreatmentTime}`))
  return filtered
}

export const withScreenStartTimeCheck = (screenOpenedAt: Date, isMnxEligible: boolean, treatmentDate: Date | null): (action: () => void) => void => {
  return (action: () => void) => {
    getCountryConfiguration()
    const useMnx = usMnx(isMnxEligible)
    const isTreatmentDateOnRushWindow = isDateWithinRushWindow(treatmentDate, useMnx, screenOpenedAt)
    const hasMnxCutOffCrossed = screenOpenedAt < todayMnxCutOffDateTime() && new Date() >= todayMnxCutOffDateTime() && isTreatmentDateOnRushWindow
    const hasBwxtCutOffCrossed = screenOpenedAt < cutOffTimeToday() && new Date() >= cutOffTimeToday()
    const isRestrictedByMnx = useMnx && (hasMnxCutOffCrossed || hasBwxtCutOffCrossed)
    const isRestrictedByBwxt = !useMnx && hasBwxtCutOffCrossed
    if (!isWeekend(new Date()) && (isRestrictedByMnx || isRestrictedByBwxt)) {
      Notification.error('Your session has expired, please refresh the page and submit your order again')
    } else {
      action()
    }
  }
}

export const convertDateOnlyToString = (date: Date, formatString: string) => {
  return moment(date).format(formatString)
}

export const getDefaultOrderFormType = (user: GetMyPortalUser_me | null): OrderFormTypeEnum => {
  if (user?.defaultOrderForm) {
    return user.defaultOrderForm;
  } else if (isROWCustomer(user)) {
    return OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED;
  } else {
    return OrderFormTypeEnum.VIAL_SELECTOR;
  }
}
